import styled from "styled-components";

export const Error404Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.primary.coleslawWhite};

  .content {
    width: 100%;
    max-width: 600px;
    text-align: center;
  }

  .topSection {
    margin-top: 60px;

    .redPartOfHeader {
      color: ${(props) => props.theme.color.primary.tongueTorchRed};
    }

    .Title {
      margin-bottom: 24px;
      font-family: ${(props) => props.theme.font?.barlow};
    }

    .spilledZaxSauce {
      position: relative;
      width: 100%;
      max-width: 300px;
      height: 200px;
      margin: 0 auto;
      margin-bottom: 32px;
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    .Description {
      margin-bottom: 24px;
      width: 404px;
    }

    .navButton {
      width: 204px;
      margin-bottom: 60px;
    }
  }

  @media (max-width: ${(props) => props.theme.size.lg}) {
    .topSection {
      margin-top: 60px;
      .spilledZaxSauce {
        width: 150px;
        height: 100px;
        margin-bottom: 20px;
      }
    }

    .bottomSection {
      .Description {
        width: 303px;
      }
      .navButton {
        width: 169px;
      }
    }
  }
`;
