import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { CMSRootState } from "redux/reducer/CMS/types";
import CMSImage from "components/global/CMSImage/CMSImage";
import { setError404Data } from "redux/reducer/CMS/CMSAction";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { Error404Styled } from "./Error404Styled";

interface RootState {
  cms: CMSRootState;
}

const Error404 = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { error404, isError404Loaded } = useSelector(({ cms }: RootState) => ({
    error404: cms.error404,
    isError404Loaded: cms.isError404Loaded,
  }));

  const handleClose = () => {
    router.push("/menu");
  };

  useEffect(() => {
    dispatch(setError404Data());
  }, []);

  if (!error404 || !isError404Loaded) return null;

  return (
    <Error404Styled>
      <div className="content">
        <div className="topSection">
          {error404.RedTitleText && (
            <Headings variant="primaryHeading1" fontSize={34} mobileFontSize={24} fontWeight={700} className="redPartOfHeader">
              {error404.RedTitleText}
            </Headings>
          )}
          {error404.BlueTitleText && (
            <SubHeadings variant="secondarySubHeading3" fontSize={36} fontWeight={700} className="Title">
              {error404.BlueTitleText}
            </SubHeadings>
          )}
          {error404.Image?.url && (
            <div className="spilledZaxSauce">
              <CMSImage
                src={error404.Image.url}
                alt={error404.Image.alternativeText || "404 Error Page Image"}
                layout="fill"
                objectFit="contain"
              />
            </div>
          )}
        </div>
        <div className="bottomSection">
          <Paragraph
            variant="primaryParagraph2"
            fontSize={16}
            mobileFontSize={12}
            mobileFontWeight={400}
            fontWeight={400}
            className="Description"
          >
            Maybe head over to our homepage and start from scratch, or check out the goodies in our menu.
          </Paragraph>
          <Button variant="primaryBlue" className="navButton" onClick={handleClose}>
            EXPLORE MENU
          </Button>
        </div>
      </div>
    </Error404Styled>
  );
};

export default Error404;
