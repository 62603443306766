import PageWrapper from "components/global/PageWrapper/PageWrapper";
import Head from "components/global/Head/Head";
import Error404 from "components/Errors/Error404/Error404";

const Error404Page = () => (
  <PageWrapper>
    <Head
      title="404 Error | Zaxbys"
      description="404 Error - Let's get you pointed in the right direction—toward your nearest Zaxbys!"
      favicon=""
    />
    <Error404 />
  </PageWrapper>
);

export default Error404Page;
